import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import Logo from '../components/Logo'
import PageWrapper from '../components/PageWrapper'
import { ReturnHomeButton } from '../components/ReturnHomeButton'
import { Title, Section, Box } from '../components/Core'

import backgroundImage from '../assets/image/jpeg/jazz.jpg'

const BoxStyled = styled(Box)`
  background-image: url(${backgroundImage});
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ShadowBox = styled(BoxStyled)`
  background: rgba(0, 0, 0, 0.5);
`

const ComingSoonPage = () => {
  return (
    <>
      <PageWrapper>
        <BoxStyled>
          <ShadowBox>
            <Box>
              <Container className='text-center'>
                <Row className='justify-content-center'>
                  <Col lg='10' xl='8'>
                    <Box className='text-center' pt='40px'>
                      <Logo white />
                    </Box>
                  </Col>
                </Row>
              </Container>
            </Box>
            <Box>
              <Container className='text-center'>
                <Row className='justify-content-center'>
                  <Col lg='10' xl='8'>
                    <Section>
                      <Title color='light'>Coming Soon</Title>
                    </Section>
                    <Section
                      css={`
                        margin-top: -50px;
                      `}
                    >
                      <ReturnHomeButton monochrome />
                    </Section>
                  </Col>
                </Row>
              </Container>
            </Box>
          </ShadowBox>
        </BoxStyled>
      </PageWrapper>
    </>
  )
}
export default ComingSoonPage
